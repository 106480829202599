.form-group input:focus {
    outline: none;
}

.formik-field_wrap {
    display: inline-block !important;
    width: 100% !important;
    margin-top: 10px !important;
}

.formik-field-left {
    width: 40% !important;
    float: left !important;
}

.formik-field-right {
    width: 40% !important;
    float: right !important;
}

.formik-field_wrap input {
    border-radius: 5px;
}

.big-modal.ant-modal {
    width: 70% !important;
}

.big-modal .ant-modal-content {
    border-radius: 4px;
}